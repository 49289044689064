import React from "react";
import "./index.scss";

const WhyAmzscout = () => {
  return (
    <section className="PgJP-WhyAmzscout">
      <div className="PgJP-WhyAmzscout__container">
        <h2 className="PgJP-WhyAmzscout__title">
          AMZScout
          PRO拡張機能は、Amazonの初心者販売者と経験豊富な販売者に最適です。
        </h2>
        <p className="PgJP-WhyAmzscout__subTitle">
          50万人以上のユーザーがいて、すでに何百万もの収益性の高い製品を見つけるのに役立っています。
        </p>
      </div>
    </section>
  );
};

export default WhyAmzscout;
