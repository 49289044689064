import React from "react"

const PG_JP_PROFITS_CONTENT = {
  firstListOfStrings: [
    "月間売上高の見積もり",
    "レビューあたりの収益パラメータ",
    "レビュー数と評価平均",
    "グーグルトレンド",
    "自動FBA料金計算",
  ],
  secondListOfStrings: [
    "最低販売価格情報",
    "リスティング品質分析",
    "自社ブランドと再販の製品スコア",
    "主な製品キーワード",
    "BSR歴史",
  ],
}
export default PG_JP_PROFITS_CONTENT
