import React from "react";
import PG_JP_PROFITS_CONTENT from "./index.content";
import "./index.scss";

const Profits = () => {
  const { firstListOfStrings, secondListOfStrings } = PG_JP_PROFITS_CONTENT;

  return (
    <section className="PgJP-Profits">
      <div className="PgJP-Profits__container">
        <h2 className="PgJP-Profits__title">
          ソフトウェアは使いやすく、ナビゲーションも簡単です。
          投資を保護するために、具体的なデータに基づいて製品を選択できます。
        </h2>
        <div className="PgJP-Profits__list">
          <ul>
            {firstListOfStrings.map((text, i) => (
              <li key={i}>
                <span>+</span>
                {text}
              </li>
            ))}
          </ul>
          <ul>
            {secondListOfStrings.map((text, i) => (
              <li key={i}>
                <span>+</span>
                {text}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Profits;
