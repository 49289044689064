import Jpg1 from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1.jpg"
import Jpg1_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@2x.jpg"
import Jpg1Tab from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@tab.jpg"
import Jpg1Tab_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@tab2x.jpg"
import Jpg1Mob from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@mob.jpg"
import Jpg1Mob_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@mob2x.jpg"
import Webp1 from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1.webp"
import Webp1_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@2x.webp"
import Webp1Tab from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@tab.webp"
import Webp1Tab_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@tab2x.webp"
import Webp1Mob from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@mob.webp"
import Webp1Mob_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-1@mob2x.webp"

import Jpg2 from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2.jpg"
import Jpg2_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@2x.jpg"
import Jpg2Tab from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@tab.jpg"
import Jpg2Tab_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@tab2x.jpg"
import Jpg2Mob from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@mob.jpg"
import Jpg2Mob_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@mob2x.jpg"
import Webp2 from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2.webp"
import Webp2_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@2x.webp"
import Webp2Tab from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@tab.webp"
import Webp2Tab_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@tab2x.webp"
import Webp2Mob from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@mob.webp"
import Webp2Mob_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-2@mob2x.webp"

import Jpg3 from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3.jpg"
import Jpg3_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@2x.jpg"
import Jpg3Tab from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@tab.jpg"
import Jpg3Tab_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@tab2x.jpg"
import Jpg3Mob from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@mob.jpg"
import Jpg3Mob_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@mob2x.jpg"

import Webp3 from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3.webp"
import Webp3_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@2x.webp"
import Webp3Tab from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@tab.webp"
import Webp3Tab_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@tab2x.webp"
import Webp3Mob from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@mob.webp"
import Webp3Mob_2x from "../../../../assets/images/raster/pages/jp/pro/ProInstall/jp-pro-install-3@mob2x.webp"

const PG_JP_PROINSTALL_CONTENT = {
  itemsDataList: [
    {
      pic: {
        pic: Jpg1,
        pic2x: Jpg1_2x,
        picTab: Jpg1Tab,
        picTab2x: Jpg1Tab_2x,
        picMob: Jpg1Mob,
        picMob2x: Jpg1Mob_2x,
        webp: Webp1,
        webp2x: Webp1_2x,
        webpTab: Webp1Tab,
        webpTab2x: Webp1Tab_2x,
        webpMob: Webp1Mob,
        webpMob2x: Webp1Mob_2x,
      },
      text: "ChromeストアのPRO拡張機能ページに移動します",
    },
    {
      pic: {
        pic: Jpg2,
        pic2x: Jpg2_2x,
        picTab: Jpg2Tab,
        picTab2x: Jpg2Tab_2x,
        picMob: Jpg2Mob,
        picMob2x: Jpg2Mob_2x,
        webp: Webp2,
        webp2x: Webp2_2x,
        webpTab: Webp2Tab,
        webpTab2x: Webp2Tab_2x,
        webpMob: Webp2Mob,
        webpMob2x: Webp2Mob_2x,
      },
      text: "「追加」ボタンをクリックして５秒お待ちます",
    },
    {
      pic: {
        pic: Jpg3,
        pic2x: Jpg3_2x,
        picTab: Jpg3Tab,
        picTab2x: Jpg3Tab_2x,
        picMob: Jpg3Mob,
        picMob2x: Jpg3Mob_2x,
        webp: Webp3,
        webp2x: Webp3_2x,
        webpTab: Webp3Tab,
        webpTab2x: Webp3Tab_2x,
        webpMob: Webp3Mob,
        webpMob2x: Webp3Mob_2x,
      },
      text: "AMZScout PRO拡張機能がChromeまたはEdgeブラウザーに追加されます- Amazonサイトで製品を閲覧し、検索ページで詳細な分析を手に入れます",
    },
  ],
}
export default PG_JP_PROINSTALL_CONTENT
