import PngDesk from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout.png"
import PngDesk2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@2x.png"
import WebpDesk from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout.webp"
import WebpDesk2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@2x.webp"

import PngXl from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@xl.png"
import PngXl2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@xl2x.png"
import WebpXl from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@xl.webp"
import WebpXl2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@xl2x.webp"

import PngMid from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@mid.png"
import PngMid2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@mid2x.png"
import WebpMid from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@mid.webp"
import WebpMid2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@mid2x.webp"

import PngTab from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@tab.png"
import PngTab2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@tab2x.png"
import WebpTab from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@tab.webp"
import WebpTab2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@tab2x.webp"

import PngMob from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@mob.png"
import PngMob2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@mob2x.png"
import WebpMob from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@mob.webp"
import WebpMob2x from "../../../../assets/images/raster/pages/jp/pro/UsingAmzscout/jp-pro-usingAmzscout@mob2x.webp"

const PG_JP_USING_AMZSCOUT_CONTENT = {
  pictureList: {
    PngDesk,
    PngDesk2x,
    WebpDesk,
    WebpDesk2x,
    PngXl,
    PngXl2x,
    WebpXl,
    WebpXl2x,
    PngMid,
    PngMid2x,
    WebpMid,
    WebpMid2x,
    PngTab,
    PngTab2x,
    WebpTab,
    WebpTab2x,
    PngMob,
    PngMob2x,
    WebpMob,
    WebpMob2x,
  },

  firstListData: {
    number: "1.",
    title: "販売する収益性の高い製品を選択する",
    stringList: [
      "ニッチにポテンシャルがあるかどうかを迅速に見積もるには、ニッチスコアをチェックする",
      "可視性スコアをチェックアして、潜在的な将来の競争を評価する",
      "下降傾向にある製品を避けるために、ニッチと製品履歴を見る",
      "純利益の理解を深めるためにFBAコストを見積もる",
    ],
  },
  secondListData: {
    number: "2.",
    title: "地元およびグローバルサプライヤーを見つける",
    stringList: [
      "新着「Alibaba.comの地元およびグローバルサプライヤー」機能を使用して、米国や中国を含むあらゆる国から手頃な価格で信頼できるサプライヤーを見つけてください",
      "ビジネスにとって最も信頼できるパートナーを見つけるには、評価と事業活動によってサプライヤーを比較する",
      "ビジネスを拡するには、競争者の製品を見つけて在庫に追加する",
    ],
  },
  thirdListData: {
    number: "3.",
    title: "競争者を凌駕する",
    stringList: [
      "製品のパフォーマンスを示す競争者の売上、価格設定、キーワード、在庫レベルを追跡する",
      "スプレッドシートでデータ分析を実行するには、その情報を含むCSVファイルをエクスポートしてダウンロードする",
    ],
  },
}
export default PG_JP_USING_AMZSCOUT_CONTENT
